//assets
import { ReactComponent as Handshake } from "../assets/icons/icon-handshake.svg";
import banner from "../assets/images/leafBannerSmaller.jpg";
import animatedIntroLarge from "../assets/video/animatedIntro-1080.mp4";
import thumb from "../assets/video/animatedIntroThumb.png";
import Guarantee from "../components/guarantee";
//components
import Layout from "../components/layout";
import SEO from "../components/seo";
import Service from "../components/service";
import Services from "../components/services";
import StyledButton from "../components/styledButton";
import cx from "classnames";
import { Link, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

function Index({ data }) {
  // const services = data.services.edges;

  return (
    <Layout
      headerClass="relative bg-white"
      hasHero={true}
      heroImage={banner}
      heroHead="City of Oaks Cremation"
      heroSubHead="Affordable Cremation and Funeral Home"
      heroText="Let us help make a difficult process a little easier."
    >
      <SEO
        title="Affordable Cremation, Burial or Funeral"
        description="City of Oaks Funeral Home provides unsurpassed service for direct cremation, traditional funerals, or immediate burial. In Raleigh, serving all of North Carolina."
      />
      <div className="flex flex-col mt-10 md:mt-16">
        <div className="w-16 h-16 mb-4 self-center">
          <Handshake />
        </div>

        <div className="font-serif font-hairline">
          <h2 className="text-3xl md:text-4xl text-green-700">
            We believe in practical, affordable solutions.
          </h2>
        </div>
        <p className="py-4">
          Simply having to reach out to a funeral home can feel overwhelming.
          Our goal is to simplify the entire funeral home experience and make
          this process as easy as possible. We hope to eliminate any unnecessary
          surprises by publishing our total cost and the services we provide
          directly on our website. Hopefully this simple gesture helps provide
          your family with a little piece of mind.
        </p>
      </div>
      <div className="flex flex-col mt-10 md:mt-10">
        <h1 className="py-4">
          City of Oaks Funeral Home and Cremation is a full-service funeral home
          offering direct cremation, immediate burial, and affordable
          traditional funeral services. We're based in Raleigh, but we serve all
          of North Carolina and beyond.
        </h1>
        <p className="py-4">
          When calling another funeral home to compare cost, it is important to
          ask how much the total invoice will be after they include additional
          costs like the cost of the alternative container, Certified Death
          Certificates, the NC Sales Tax and any other additional fees. Our
          prices already include all of those things.{" "}
          <a
            class="hover:text-green-600 underline"
            href="https://www.wral.com/how-much-does-it-cost-to-die-in-wake-county-/17514302/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Raleigh news station WRAL thinks our belief in transparency is
            newsworthy.
          </a>
        </p>
        <p>
          If you find any of our services less expensive on a current General
          Price List provided to you by another licensed funeral home in Wake
          County, NC, we will match the cost of that item and discount it by an
          additional 10%.
        </p>

        {/* services block here */}
        {/* <p className='mb-2 text-4xl text-gray-800 self-center'>Our Services</p>
        <div className='flex flex-wrap justify-center items-stretch -mx-2'>
          {services
            .sort(
              (a, b) => a.node.frontmatter.weight - b.node.frontmatter.weight
            )
            .map(({ node }) => (
              <Service
                title={node.frontmatter.title}
                slug={node.frontmatter.slug}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
                icon={node.frontmatter.icon}
                price={node.frontmatter.price}
                blurb={node.frontmatter.blurb}
              ></Service>
            ))}
        </div> */}
        <Services />
      </div>
      <div className="w-full mt-6"> </div>
      <div className="w-full mt-6 ml-auto mr-auto">
        <video
          style={{
            background: `#4e5458`,
          }}
          poster={thumb}
          controls
        >
          <source src={animatedIntroLarge} />
        </video>
      </div>
      <Guarantee />

      <div className="flex flex-col mt-10 md:mt-16">
        <p className="mb-2 text-3xl text-green-700 self-center pb-4 font-serif">
          City of Oaks is committed to providing families with unsurpassed and
          professional service before, during, and after the time of loss.
        </p>
        <p className="py-3">
          Our Funeral Home is located in Wake County, North Carolina; however,
          we provide our services to families throughout the entire state.
          Regardless of what County you live in, we can help your family with
          making simple, affordable funeral arrangements.
        </p>
        <p className="py-3">
          We have an all-inclusive, low cost approach. The families we serve are
          typically educated, practical, environmentally conscious and
          economically sound decision makers. More frequently, families are
          deciding to bypass the lavish floral displays, catered receptions,
          expensive hearses and family cars or limousines that have often been
          associated with traditional American funerals of the past.
        </p>
        <p className="py-3">
          We provide each family with a Licensed Funeral Director who will work
          with you from start to finish. Our team takes pride in being able to
          assist families with a wide range of cultures, religions, economic and
          other influencing factors. We provide compassionate care and
          understanding to families at the time of a pending death by making
          sure they are informed of what steps will follow and how we will be
          there to assist them through the entire process. Our Funeral Directors
          provide the reassuring voice that families rely on while going through
          a difficult time when a loved one passes away.
        </p>
        <div className="self-center my-3">
          <Link to="/services/">
            <StyledButton>Our services</StyledButton>
          </Link>
        </div>

        <div className="self-center my-3">
          <Link to="/cities/">
            <StyledButton>State-wide and beyond</StyledButton>
          </Link>
        </div>

        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-0 text-3xl text-gray-800 self-center">
            Chapel Capacity Limit
          </p>
          <p className="text-center self-center">
            City of Oaks Cremation, LLC limits our on-site memorial services,
            visitations, funeral services and/or religious services to a maximum
            of 44 individuals in attendance. The City of Raleigh believes our
            intimate chapel is simply not large enough to safely accommodate
            larger groups. We completely agree... for this reason, all services
            held onsite are required to be private as well as unadvertised.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
            weight
          }
          excerpt
        }
      }
    }
  }
`;

export default Index;
